import DB from '../db';
import { saveMBinDB, editMBinDB } from '../requests/boardsAPI';
import inMemoryID from '../services/id';
import { sendDownloadRequest } from '../requests/imagesAPI';
import GetImageAspectRatio from './getImageAspectRatio';

// function that saves moodBoard in indexedDB
export const saveMoodBoardInDB = async (
    Grid,
    MoodBoardName,
    createdID,
    RecentSearchPhrases,
    usersWithCommentsAccess,
    createdBy
) => {
    const allImagesDownloadURLs = [];

    const saveMBToIndexedDB = async (Grid) => {
        const savedPhotosArray = [];

        if (Grid.current) {
            const gridItems = Array.from(
                Grid.current.querySelectorAll('.grid-item-cont')
            );

            const promises = gridItems.map(async (I) => {
                const orderInMB = I.id.replace('cont-', '');
                const image = JSON.parse(I.dataset.image);

                const saveSingleImage = (imageInfo) => {
                    let src;

                    if (imageInfo.url) {
                        if (
                            imageInfo.url.includes(
                                'https://images.unsplash.com/'
                            )
                        ) {
                            src = 'https://unsplash.com/';
                        } else if (
                            imageInfo.url.includes('.staticflickr.com/')
                        ) {
                            src = 'https://www.flickr.com/';
                        } else {
                            src = 'Local';
                        }
                    }

                    const savedElem = {
                        id: imageInfo.id,
                        alt: imageInfo.alt,
                        url: imageInfo.url,
                        order: orderInMB,
                        resource: src,
                        user: imageInfo.user,
                        download_location: imageInfo.download_location,
                        imageGridSize: imageInfo.imageGridSize,
                        comments: imageInfo.comments ? imageInfo.comments : [],
                    };

                    if (
                        imageInfo.download_location &&
                        imageInfo.alreadyDownloaded === false
                    ) {
                        allImagesDownloadURLs.push(imageInfo.download_location);
                        imageInfo.alreadyDownloaded = true;
                        I.dataset.image = JSON.stringify(imageInfo);
                    }

                    savedPhotosArray.push(savedElem);
                };

                if (!image.imageGridSize) {
                    await new Promise((resolve, reject) => {
                        GetImageAspectRatio(image.url, (err, data) => {
                            if (err) {
                                reject(err);
                            } else {
                                image.imageGridSize = data;
                                saveSingleImage(image);
                                resolve();
                            }
                        });
                    });
                } else {
                    saveSingleImage(image);
                }
            });

            // Wait for all promises to complete
            await Promise.all(promises);

            return savedPhotosArray;
        }
    };

    const newMoodBoard = await saveMBToIndexedDB(Grid);

    let id;

    if (newMoodBoard.length >= 1) {
        if (!MoodBoardName || MoodBoardName.trim() === '') {
            MoodBoardName = 'Untitled board';
        }

        if (createdID) {
            const board = await DB.moodBoards.get({ mbid: createdID });
            await editMBinDB(
                newMoodBoard,
                MoodBoardName,
                createdID,
                RecentSearchPhrases,
                createdBy
            );
            if (board !== undefined) {
                await DB.moodBoards.update(board.id, {
                    name: MoodBoardName,
                    photos: newMoodBoard,
                    mbid: createdID,
                    recentSearches: RecentSearchPhrases,
                    users_with_access: usersWithCommentsAccess
                        ? usersWithCommentsAccess
                        : [],
                });
            }
        } else {
            const res = await saveMBinDB(
                newMoodBoard,
                MoodBoardName,
                RecentSearchPhrases,
                inMemoryID.getID()
            );
            id = res.data._id;
            await DB.moodBoards.add({
                created_by: inMemoryID.getID(),
                name: MoodBoardName,
                mbid: id,
                photos: newMoodBoard,
                recentSearches: RecentSearchPhrases,
                users_with_access: usersWithCommentsAccess
                    ? usersWithCommentsAccess
                    : [],
            });

            if (!inMemoryID.getID()) {
                await DB.moodBoards.add({
                    name: MoodBoardName,
                    photos: newMoodBoard,
                    recentSearches: RecentSearchPhrases,
                });
            }
        }
    }

    if (allImagesDownloadURLs.length > 0) {
        await sendDownloadRequest(allImagesDownloadURLs);
    }

    return id;
};
