import React, { useEffect } from "react";
import {clearBoardPopUpOpen} from '../../scripts/script'

function ClearMoodBoardPopUp(props) {

    function clearMoodBoard() {
        props.clearMB()
        clearBoardPopUpOpen()
    }
    
    return (
        <div className="all" id="clear-board">
            <a className="popup-close" onClick={clearBoardPopUpOpen}></a>
            <div className="clear-moodboard-popup">
                <h2>Clean-up moodboard</h2>
                <p>If you want to clean-up a mood board, remember that you can no longer restore it!</p>
                <div className="buttons-horizontal-cont">
                    <button onClick={clearMoodBoard}>Clean</button>
                    <button onClick={clearBoardPopUpOpen}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default ClearMoodBoardPopUp