import React from 'react';
import { HashLoader, ScaleLoader } from 'react-spinners';
import { Oval, ColorRing } from 'react-loader-spinner'


function LoadingScreen(props) {

  const override = {
    display: 'block',
  }
    
  return (
      <HashLoader style={override} size={100} color={'#828282'} loading={props.loading} />
    )
};

function PreviewLoadingScreen(props) {

  const override = {
    display: 'block',
    position: 'absolute',
    margin: '0 auto',
    zIndex: '1',
    marginTop: '38vh',
  }
    
  return (
      <HashLoader style={override} size={100} color={'#828282'} loading={props.loading} />
    )
};

function SearchLoadingScreen() {
  return (
    <ColorRing
      visible={true}
      height="80"
      width="80"
      ariaLabel="color-ring-loading"
      wrapperStyle={{
        margin: '20%'
      }}
      wrapperClass="color-ring-wrapper"
      colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
      />
  )
}

export {LoadingScreen, PreviewLoadingScreen, SearchLoadingScreen}