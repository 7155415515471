import axios from "axios";
import { backEndImages } from '../constants/API'
import JWTAccess from '../services/memory'
import APIclient from './client'

const imagesClient = new APIclient(backEndImages)

// call to Unsplash and Flickr API
async function callToImageServices(search, page, searchphotoOrientation, searchphotoColorTheme, searchUnsplash, searchFlickr) {

    if (!searchUnsplash) { searchUnsplash = false }
    if (!searchFlickr) { searchFlickr = false }

    const params = {
        search: search,
        page: page,
        searchphotoOrientation: searchphotoOrientation,
        searchphotoColorTheme: searchphotoColorTheme,
        searchUnsplash: searchUnsplash,
        searchFlickr: searchFlickr
    }

    const resultImages = await imagesClient.get('/result-images', {
        params: params
    }).catch((err) => {
       
    })

    return resultImages.data
}

export async function getPhotosFromMain() {

    const resultImages = await imagesClient.get('/main-images').catch((err) => {
        
    })

    return resultImages.data
}

export async function sendDownloadRequest(url) {

    await imagesClient.post('/image-download', {
        urls: url
    }).then((res) => {

    }).catch((err) => {
        throw err
    })

}

export default callToImageServices