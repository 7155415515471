import React, { useState, useRef, useEffect } from "react"
import { verifySignUpEmail, handleSignUp } from '../../requests/authAPI'
import { signUp_popup_open, signIn_popup_open } from "../../scripts/script"
import { ErrorPopUp } from '../errors'
import validationSchema from '../../validators/auth'
import { useNavigate } from "react-router-dom"
import { auth, provider } from '../../firebaseConnect';
import inMemoryID from '../../services/id';
import { signInWithPopup } from 'firebase/auth'
import { LoadingScreen } from '../loader'
import '../../style.css';


function SignUpPopUp(props) {

    const [signUpPass, setSignUpPass] = useState()

    const [signUpConfirmPass, setSignUpConfirmPass] = useState()

    const [signUpEmail, setSignUpEmail] = useState();

    const [errorPopUp, setErrorPopUp] = useState()

    const [loader, setLoader] = useState();

    const navigate = useNavigate()

    const navigateToVerifyPage = () => {
        navigate('/verify-email')
    }

    const activateLoader = () => {
        setLoader(
            <div style={{height: '100%', width: '100%', background: '#FFF', position: 'absolute', top: '0', borderRadius: '5px'}}>
                <LoadingScreen loading={true} />
            </div>
        )
    }

    const isButtonPressed = useRef(false);

    async function signUp() {
        if (signUpEmail && signUpPass && !inMemoryID.getID() && !isButtonPressed.current) {
            if (signUpPass.trim() === signUpConfirmPass.trim() && signUpEmail.trim() != '' && signUpPass.trim() != '') {
                validationSchema.validate({ email: signUpEmail, password: signUpPass })
                    .then(async () => {
                        activateLoader()
                        isButtonPressed.current = true;
                        await verifySignUpEmail(signUpEmail, signUpPass, navigateToVerifyPage).catch((err) => {
                            isButtonPressed.current = false;
                            setLoader(null)
                            setErrorPopUp(err)
                    })
                }).catch((err) => {
                    isButtonPressed.current = false;
                    setLoader(null)
                    setErrorPopUp(err)
                })
            }
        }
    }

    async function signUpWithGoogle() {
        if (!inMemoryID.getID() && !isButtonPressed.current) {
            isButtonPressed.current = true;
            activateLoader()
            signInWithPopup(auth, provider).then(async (result) => {
                const user = result.user;
                
                await handleSignUp(user.uid).catch((err) => {
                    isButtonPressed.current = false;
                    setLoader(null)
                    setErrorPopUp(Error(err.response.data.error))
                })
            }).catch((err) => {
                isButtonPressed.current = false;
                setLoader(null)
                setErrorPopUp(Error(err.code))
            })
        }
    }

    return (
        <>
            <div className="all" id="signUp">
                <a onClick={signUp_popup_open} className="popup-close"></a>
                <div className="sign-up-popup">
                    <div className="input-box">
                        <h2>Get started</h2>
                        <p style={{margin: "59px 0 0 0"}}>Email</p>
                        <div className="search-box-3">
                        <input className="input-2" type="email" autoComplete="on" required onChange={(e) => {setSignUpEmail(e.target.value)}} />
                        </div>
                        <p>Password</p>
                        <div className="search-box-3">
                        <input className="input-2" type="password" required onChange={(e) => {setSignUpPass(e.target.value)}} />
                        </div>
                        <p>Confirm Password</p>
                        <div className="search-box-3" style={{marginBottom:"30px"}}>
                        <input className="input-2" type="password" required  onChange={(e) => {setSignUpConfirmPass(e.target.value)}} />
                        </div>
                        <button className="btn-confirm" onClick={signUp}>Sign up</button>
                        <p style={{ color: "grey", fontSize: '10px', textAlign: 'center', margin: '16px 0 0 0' }}>
                            By clicking "Sign up" or "Continue with Google", you agree to the
                            Gettyboards Terms of service and Privacy Policy.
                        </p>
                        <button className="btn-confirm" onClick={signUpWithGoogle}>Continue with Google</button>
                        <p style={{ color: "grey", textAlign: 'center', margin: '30px 0 0 0', fontSize: '13px' }}>Already have an account?
                            <a style={{ color: 'rgb(45, 156, 219)' }} onClick={signIn_popup_open}> Sign In</a>
                        </p>
                    </div> 
                    <ErrorPopUp message={errorPopUp} />
                    {loader}
                </div>
            </div>
        </>
    )
}

export {SignUpPopUp}