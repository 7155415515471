import React, { useState, useEffect, useRef } from "react";

function AutoSavePopUp(props) {

    const [currentTime, setCurrentTime] = useState();

    const [currentDate, setCurrentDate] = useState();

    useEffect(() => {
        if (props.currentTime) {
            const time = props.currentTime

            const hours = time.getHours() <= 9 ? '0' + time.getHours() : time.getHours();

            const minutes = time.getMinutes() <= 9 ? '0' + time.getMinutes() : time.getMinutes();

            const day = time.getDate() <= 9 ? '0' + time.getDate() : time.getDate();

            const NumberMonth = Number(time.getMonth()) + 1;

            const month = NumberMonth <= 9 ? '0' + NumberMonth : NumberMonth;

            setCurrentTime(hours + ':' + minutes);
    
            setCurrentDate(day + '-' + month + '-' + time.getFullYear());
        }
    }, [props.currentTime]);

    return (
        <div className="auto-save-popup">
            <p>{currentTime}</p>
            <p>{currentDate}</p>
            <h2>Autosaved!</h2>
        </div>
    );
}

export default AutoSavePopUp