import React, { useEffect, useRef } from "react";
import { deleteBoardPopUpOpen } from '../../scripts/script'

function DeleteMoodBoardPopUp(props) {

    const isButtonPressed = useRef(false);

    async function deleteBoard() {
        if (!isButtonPressed.current) {
            isButtonPressed.current = true
            await props.deleteFunc()
        }
    }

    const closePopUp = () => {
        deleteBoardPopUpOpen(props.id)
    }
    
    return (
        <div className="all" id={"delete-board-"+props.id}>
            <a onClick={closePopUp} className="popup-close"></a>
            <div className="delete-moodboard-popup">
                <h2>Delete moodboard</h2>
                <p>If you want to delete a mood board, remember that you can no longer restore it!</p>
                <div className="buttons-horizontal-cont">
                    <button onClick={deleteBoard}>Delete</button>
                    <button onClick={closePopUp}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default DeleteMoodBoardPopUp