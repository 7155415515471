import * as React from 'react';
import { styled } from '@mui/material/styles';
import ToolTip, { tooltipClasses } from '@mui/material/Tooltip';

// tooltip
const CustomToolTip = styled(({className, ...props}) => (
    <ToolTip {...props} arrow classes={{popper: className}}/>

))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
        color: theme.palette.common.black,
    },
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.black,
    },
}))

export default CustomToolTip 