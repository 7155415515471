import React, { useEffect, useState, useRef } from "react";
import { renameBoardPopUpOpen } from '../scripts/script'
import close from '../images/close-icon.svg';

function RenameMoodBoardPopUp(props) {

    const inputRef = useRef();

    useEffect(() => {
        inputRef.current.value = props.boardName;
    }, [props.boardName])

    const renameBoard = (e) => {
        if (inputRef.current.value) {
            if (inputRef.current.value.trim() != '') { props.renameFunc(inputRef.current.value) };
            renameBoardPopUpOpen();
        }
    }

    const cancelRename = () => {
        inputRef.current.value = props.boardName;
        renameBoardPopUpOpen();
    }

    return (
        <div className="all" id="rename-board-background">
            <a className="popup-close" onClick={renameBoardPopUpOpen}></a>
            <div className="rename-board">
                <div style={{position: 'absolute', top: '0', right: '0', cursor: 'pointer'}} onClick={cancelRename}>
                        <img src={close} alt="close icon"/>
                </div>
                <h1>Rename board</h1>
                <p>Enter a new board name:</p>
                <input ref={inputRef} type="text" />
                <div className="buttons-horizontal-cont" style={{justifyContent: 'start'}}>
                    <button onClick={renameBoard}>Save</button>
                    <button onClick={cancelRename} style={{ marginLeft: '16px' }}>Cancel</button>
                </div>
            </div>
        </div>
    )
}

export default RenameMoodBoardPopUp