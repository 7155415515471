import jsPDF from 'jspdf';

// creating a function to install MoodBoard pdf file
export function saveMBpdf(Grid, MoodBoardName) {
  let height = Grid.offsetHeight;
  let width = Grid.offsetWidth;
  let aspectRatio = width/height;
  let pageSides;

  if (aspectRatio >= 1) {
    pageSides = 'l';
  } else if (aspectRatio < 1) {
    pageSides = 'p';
  }

  const widthMargin = width / 200;
  
  const widthOnePercent = width / 100;

  if (Grid) { 
    const marginOptions = [widthMargin, widthMargin, widthMargin, widthMargin]
    
    // const doc = new jsPDF(pageSides, 'px', [width+width/100, height+width/100]);
    const doc = new jsPDF(pageSides, 'px', [width, height]);

    Array.from(Grid.children).forEach((elem) => {
      if (elem.lastElementChild) {
        const topPosition = Number(elem.style.top.replace('px', ''))
        const leftPosition = Number(elem.style.left.replace('%', '') * widthOnePercent)
        doc.addImage(elem.lastElementChild.src, 'JPEG', leftPosition, topPosition, elem.lastElementChild.clientWidth, elem.lastElementChild.clientHeight)
      }
    })

    if (MoodBoardName == undefined || MoodBoardName == 'Untitled board') {
      doc.save('MoodBoard');
    } else {
      doc.save(MoodBoardName);
    }
  }
}
