import React, {useRef, useEffect, useState} from "react";
import Draggabilly from 'draggabilly';
import minus from '../images/minus_icon.svg';
import GetImageAspectRatio from '../scripts/getImageAspectRatio'

// Deleting photo from moodBoard section
function DeletePhotoFromGrid(elem) {
    let btnPlus = document.getElementById(elem.target.id.replace('minus-', ''));
    try {
        btnPlus.parentElement.style.display = "grid";
    } catch (err) {

    }
}

// Adding item to moodBoard section on the right
export function AddPhotoToGrid(gridProps) {

    const [dimensions, setDimensions] = useState();

    const [data, setData] = useState();

    useEffect(() => {
        if (gridProps.photoData) {
            setData(JSON.parse(gridProps.photoData))
        }
    }, [gridProps.photoData])

    const pckry = gridProps.pckry;

    const elem = gridProps.elem;

    const callToDelete = (e) => {
        gridProps.removeFunc(gridProps.reactKey)
        DeletePhotoFromGrid(e)
        pckry.layout()
    }

    let gridSection = document.querySelector(".grid-section");
    gridSection.style.display = "block";

    let btn = document.getElementById(elem.target.id)
    let btnCont = document.getElementById(btn.parentElement.id);

    btnCont.parentElement.style.display = "none";

    const elementRef = useRef(null);

    const isElementAddedToGrid = useRef(false)

    useEffect(() => {
        const gridItem = elementRef.current;

        GetImageAspectRatio(elem.target.dataset.url, (err, data) => {
            setDimensions(data)
            if (err) { console.log(err) }
        })

        if (gridItem && pckry && !isElementAddedToGrid.current) {
            let draggieImg = new Draggabilly(gridItem);
            pckry.bindDraggabillyEvents(draggieImg);
            pckry.addItems(gridItem);
            isElementAddedToGrid.current = true
        }
       
    }, [pckry, gridProps.photoData])

    const imageInfo = {
        id: Object.values(elem.target)[0].memoizedProps.value,
        alt: (btnCont.previousSibling.alt),
        url: elem.target.dataset.url,
        user: data ? data.user : null,
        alreadyDownloaded: false,
        download_location: data ? data.download_location : null,
        imageGridSize: dimensions,
        comments: data ? data.comments : [],
    }

    return (
        <div className="grid-item-cont" data-image={JSON.stringify(imageInfo)} key={Object.values(elem.target)[0].memoizedProps.value} ref={elementRef}>
            <div className="grid-hover-zone"></div>
            <div className='small-minus-icon'>
                <img id={`minus-${btn.parentElement.id}`} style={{height: '24px'}} onClick={callToDelete} src={minus} onTouchEnd={callToDelete}/>
            </div>
            <img className="grid-item" src={btnCont.previousSibling.src} alt={btnCont.previousSibling.alt} style={{width: '100%', position: 'relative'}} />
        </div>
    )
}


// Adding elements to selectedImages state if users editing moodBoard
export function AddEditPhotoToGrid(gridProps) {

    const [dimensions, setDimensions] = useState();

    const pckry = gridProps.pckry;

    const item = gridProps.item;

    let gridSection = document.querySelector(".grid-section");
    gridSection.style.display = "block";

    const callToDelete = (e) => {
        gridProps.removeFunc(gridProps.reactKey)
        DeletePhotoFromGrid(e)
        pckry.layout()
    }

    const elementRef = useRef(null);

    const isElementAddedToGrid = useRef(false)

    useEffect(() => {

        const gridItem = elementRef.current;

        GetImageAspectRatio(item.url, (err, data) => {
            setDimensions(data)
            if (err) { console.log(err) }
        })

        if (gridItem && pckry && !isElementAddedToGrid.current) {
            let draggieImg = new Draggabilly(gridItem);
            pckry.bindDraggabillyEvents(draggieImg);
            pckry.addItems(gridItem);
            isElementAddedToGrid.current = true
        }
    }, [pckry, gridProps.item])

    const imageInfo = {
        id: item.id,
        alt: (item.alt),
        url: item.url,
        user: item.user ? item.user : null,
        alreadyDownloaded: true,
        download_location: item.user ? item.download_location : null,
        imageGridSize: dimensions,
        comments: item.comments ? item.comments : [],
    }

    return (
        <div className="grid-item-cont" data-image={JSON.stringify(imageInfo)} key={item.id} ref={elementRef} >
            <div className="grid-hover-zone"></div>
            <div className='small-minus-icon'>
                <img id={`minus-edit-${gridProps.index}`} style={{height: '24px'}} onClick={callToDelete} onTouchEnd={callToDelete} src={minus} />
            </div>
            <img className="grid-item" src={item.url} alt={item.alt} style={{width: '100%', position: 'relative'}} />
        </div>
    )
}