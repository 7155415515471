import { useRef, useEffect } from "react";

function useAutoSave(callback, delay = 1000, deps = []) {
    const savedCallBack = useRef();

    useEffect(() => {
        savedCallBack.current = callback;
    }, [callback])

    useEffect(() => {

        function runCallback() {
            savedCallBack.current()
        }

        if (typeof delay === 'number') {

            let interval = setInterval(runCallback, delay);

            return () => clearInterval(interval)
        }

    }, [delay, ...deps])
}

export default useAutoSave