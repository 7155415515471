import React, { useEffect, useCallback, useState, useRef, useMemo } from "react";
import ArrowLeft from "../images/arrow-left-preview.svg";
import ArrowRight from '../images/arrow-right-preview.svg';
import closeIcon from '../images/white-close-icon.svg';
import GetImageAspectRatio from '../scripts/getImageAspectRatio'
import ContextMenu from "./contextMenu";
import CommentsGrid from './commentsGrid'
import inMemoryID from '../services/id';

function PreviewImageWindow(props) {

    const [isContextMenuVisible, setContextMenuVisible] = useState(false);

    const [contextMenuData, setContextMenuData] = useState({ commentPosition: { x: null, y: null }, xPos: null, yPos: null, imageData: null });

    const { setPreviewImageData, previewImageData, adjustContextMenuZoneSize } = props;

    const [userData, setUserData] = useState({ link: null, name: null })

    useEffect(() => {
        if (previewImageData) {
            if (previewImageData.user) {
                setUserData(previewImageData.user)
            } else {
                setUserData({ link: null, name: null })
            }
        }
    }, [previewImageData])

    const [isUserHaveAccess, setUserAccess] = useState(false);

    useEffect(() => {

        setTimeout(() => {
            if (props.previewMoodBoard) {
    
                if (props.previewMoodBoard.created_by === inMemoryID.getID()) {
                    setUserAccess(true)
                }
    
                if (props.previewMoodBoard.users_with_access) {
                    props.previewMoodBoard.users_with_access.forEach((user) => {
                        if (user.users_uid === inMemoryID.getID() && user.can_comment === true) {
                            setUserAccess(true)
                        }
                    })
                }
            }
        }, 1000)

    }, [props.previewMoodBoard])

    const imageRef = useRef();

    useEffect(() => {
        if (imageRef.current) {

            const func = () => {
                adjustContextMenuZoneSize(imageRef.current);
            }

            window.addEventListener('resize', func)
        }
    }, [])

    const GetUsersClickPosition = (e) => {

        if (props.previewMoodBoard.created_by === inMemoryID.getID() || isUserHaveAccess === true) {
            // Calculate the relative click position within the grid
    
            const imageData = JSON.parse(imageRef.current.dataset.image);
    
            const rect = e.target.getBoundingClientRect();
    
            let x = (e.clientX - rect.left); // x position within the element.
            let y = (e.clientY - rect.top); // y position within the element.
    
            const height = e.target.offsetHeight;
            const width = e.target.offsetWidth;
    
            GetImageAspectRatio(imageRef.current.src, (err, data) => {
                const ClickCorrelationToHeight = height / y;
    
                const ClickCorrelationToWidth = width / x;
    
                const gridX = Math.floor(data.x / ClickCorrelationToWidth);
                const gridY = Math.floor(data.y / ClickCorrelationToHeight);
    
                setContextMenuData({ commentPosition: { x: gridX, y: gridY }, xPos: 100 * (gridX / data.x), yPos: 100 * (gridY / data.y), imageData: imageData })
    
                setContextMenuVisible(true);
    
                // to convert virtual grid values to normal image size, use gridX / data.x and gridY / data.y
            });
        }
    }

    const handleKeyPress = useCallback((event) => {
        if (props.imagePreviewWindow.current.classList[1] === 'active') {
            if (event.key === 'ArrowRight') {
                changeImgRight();
            } else if (event.key === 'ArrowLeft') {
                changeImgLeft();
            } else if (event.key === 'Escape') {
                Close_preview()
            }
        }
    }, [props.photoArrayLength]);

    useEffect(() => {
        if (props.imagePreviewWindow.current) {
            document.addEventListener('keydown', handleKeyPress);
    
            return () => {
                document.removeEventListener('keydown', handleKeyPress);
            };
        }
    }, [handleKeyPress]);

    // changing image on img-preview to the right
    function changeImgRight() {
        let img = document.querySelector(".preview-image");
        let elementId = img.id.replace('img-', '');
        if (elementId == props.photoArrayLength) {
            return
        } else {
            setContextMenuVisible(false)

            elementId++
            let next = document.getElementById('img-' + elementId);
            
            if (next) {
                const imageData = next.parentElement.getAttribute('data-image');

                const decodedData = JSON.parse(imageData);
    
                img.src = next.src;
                img.id = next.id;
                img.setAttribute('data-image', imageData)

                img.onload = () => {
                    if (img.src === next.src) {
                        setPreviewImageData(decodedData);
                        adjustContextMenuZoneSize(img);
                    }
                };
            }
        }
    };

    // changing image on img-preview to the left
    function changeImgLeft() {
        let img = document.querySelector(".preview-image");
        let elementId = img.id.replace('img-', '');
        if (elementId == 1) {
            return
        } else {
            setContextMenuVisible(false)

            elementId--
            let prev = document.getElementById('img-' + elementId);

            if (prev) {
                const imageData = prev.parentElement.getAttribute('data-image');

                const decodedData = JSON.parse(imageData);
                
                img.src = prev.src
                img.id = prev.id;
                img.setAttribute('data-image', imageData)

                img.onload = () => {
                    if (img.src === prev.src) {
                        setPreviewImageData(decodedData);
                        adjustContextMenuZoneSize(img);
                    }
                };
            }
        }
    };

    // closing img-preview
    function Close_preview() {

        setContextMenuVisible(false)

        setPreviewImageData(null)
        
        let preview = document.querySelector(".img-preview");
        let img = document.querySelector(".preview-image");
        img.id = null;
        preview.classList.toggle("active");
    };

    return (
        <div className="img-preview" ref={props.imagePreviewWindow}>
            <div onClick={Close_preview} className="close-preview">
                <img src={closeIcon}/>
            </div>
            <div className="change-image-zone">
                <div className="change-img-left" onClick={changeImgLeft}> 
                    <img src={ArrowLeft}/>
                </div>
                <div className='preview-image-cont'>
                    <div className='alt-text-holder' style={{height: '14%'}}>
                        <p>{ previewImageData ? previewImageData.alt : null }</p>
                    </div>
                    <div className="preview-image-holder">
                        <div className="context-menu-container" onDoubleClick={GetUsersClickPosition} >
                            <ContextMenu setPreviewImageData={setPreviewImageData} setVisible={setContextMenuVisible} isVisible={isContextMenuVisible} data={contextMenuData} />
                            <CommentsGrid isUserHaveAccess={isUserHaveAccess} image={previewImageData} setPreviewImageData={setPreviewImageData} />
                        </div>
                        <img className="preview-image" alt="preview image" ref={imageRef} />
                    </div>
                    <div className='alt-text-holder'>
                        { userData.link ? <p style={{opacity: '0.7'}} >Photo by <a target="_blank" style={{ color: 'rgb(45, 156, 219)' }} href={userData.link+'?utm_source=gettyboards&utm_medium=referral'} >{userData.name}</a> on <a target="_blank" style={{ color: 'rgb(45, 156, 219)' }} href="https://unsplash.com/?utm_source=gettyboards&utm_medium=referral">Unsplash</a> </p> : null}
                    </div>
                </div>
                <div className="change-img-right" onClick={changeImgRight}>
                    <img src={ArrowRight}/>
                </div>
            </div>
        </div>  
    )
}

export default PreviewImageWindow