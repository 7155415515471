import React from "react";
import { useEffect, useState } from "react";
import axios from 'axios';
import { getPhotosFromMain } from '../requests/imagesAPI'

function FetchPhotos() {
    const [unsplashData, setUnsplashData] = useState([]);

    useEffect(() => {
        fetchDataFromUrls();
    }, [])

    async function fetchDataFromUrls() {
        
        try {
            const response = await getPhotosFromMain()
            setUnsplashData(response)
        } catch (error) {
            
        }
        
    }

    const rowStyles = {
        width: '100%',
        display: 'flex',
        rowGap: '5px',
        flexFlow: 'column nowrap'
    }

    function CreateRow(props) {
        return (
            <div style={rowStyles} id={"row-"+props.index} key={"row-"+props.index}> {props.imgList} </div>
        )
    }

    function CreateImg(props) {
        return (
            <img id={"img-"+props.index} src={props.source} alt={props.alt} key={props.index} />
        )
    }

    let ImageArray = [];

    const RowArray = [];

    let index = 0;
    let rowIndex = 0;
    let imgIndex = 0;
    if (unsplashData[0]) {
        unsplashData.forEach(i => {
            index++
            imgIndex++
            ImageArray.push(<CreateImg index={imgIndex} source={i.urls.small} alt={i.alt_description} key={"react-image-key-"+imgIndex} />)
            if (index > 5) {
                rowIndex++
                index = 0;
                RowArray.push(<CreateRow index={rowIndex} imgList={ImageArray} key={"react-row-key-"+rowIndex}  />)
                ImageArray = [];
            } 
        })
    }

    return (
        <>
            {RowArray}
        </>
    )
};


export {FetchPhotos}