import { getAuth, GoogleAuthProvider } from "firebase/auth";
import { initializeApp } from "firebase/app";

const firebaseConfig = {
    apiKey: "AIzaSyDcdmBXlMqNndavdRxKeCwMFOM1i2QQJ7Q",
    authDomain: "gettyboards-ed3f3.firebaseapp.com",
    projectId: "gettyboards-ed3f3",
    storageBucket: "gettyboards-ed3f3.appspot.com",
    messagingSenderId: "939930343125",
    appId: "1:939930343125:web:b15d48a13f7e94421cf45c",
    measurementId: "G-7M6L7YEYY0"
};

const defaultApp = initializeApp(firebaseConfig)
const auth = getAuth(defaultApp);

const provider = await new GoogleAuthProvider();

export { defaultApp, auth, provider };