import React, { useEffect, useState, useRef } from "react";
import plus from '../images/plus-scale-icon.svg'
import minus from '../images/minus-scale-icon.svg'
import verticalResize from '../images/resize-vertical-square.svg';
import horizontalResize from '../images/resize-horizontal-square.svg';


function ChangeScaleButtons(props) {

    const [widthInPercentage, setWidthInPercentage] = useState()

    const scaleImageRef = useRef();

    function getCurrentWidth() {
        const previewGrid = document.querySelector('.preview-grid');
        if (previewGrid) {
            const widthInPixels = previewGrid.offsetWidth;
            const parentWidth = previewGrid.parentElement.offsetWidth;
            setWidthInPercentage(((widthInPixels / parentWidth) * 100).toFixed(0));
        }
    }

    useEffect(() => {
        const timeOut = props.itemsInBoard * 0.3;

        if (props.itemsInBoard) {
            setTimeout(() => {
                autoScaleFunc()
            }, timeOut * 1000)
        }
    }, [])

    const plusScaleFunc = () => {
        props.plusScale()
        getCurrentWidth()
    }

    const minusScaleFunc = () => {
        props.minusScale()
        getCurrentWidth()
    }

    const autoScaleFunc = () => {
        const isBoardFitInHeight = props.autoScale()
        if (isBoardFitInHeight && scaleImageRef.current) {
            scaleImageRef.current.src = horizontalResize;
        } else if (!isBoardFitInHeight && scaleImageRef.current) {
            scaleImageRef.current.src = verticalResize;
        };
        getCurrentWidth()
    }

    return (
        <div className='change-scale-section'>
            <div></div>
            <div className="plus-minus-buttons">          
                <button onClick={minusScaleFunc}>
                    <img src={minus} alt="minus icon" />
                </button>
                <p>{widthInPercentage}%</p>
                <button onClick={plusScaleFunc}>
                    <img src={plus} alt="plus icon" value={"plus"} />
                </button>
            </div>
            <button className='auto-scale' style={{justifyContent: 'flex-start'}} onClick={autoScaleFunc}>
                <img ref={scaleImageRef} />
            </button>  
        </div>
    )
}

export default ChangeScaleButtons