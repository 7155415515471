import { widthGrid } from "../constants/imageGrid";

async function GetImageAspectRatio(url, cb) {

    const image = new Image();

    image.onload = () => {

        const height = image.naturalHeight;
        const width = image.naturalWidth;

        const gcd = (...arr) => {
            const _gcd = (x, y) => (!y ? x : gcd(y, x % y));
            return [...arr].reduce((a, b) => _gcd(a, b));
        };

        const gcdResult = gcd(width, height);

        const WidthMultiplier = widthGrid / (width / gcdResult);

        const HeightAspectRatio = height / gcdResult;

        const heightGrid = Number((WidthMultiplier * HeightAspectRatio).toFixed(0));

        cb(null, { x: widthGrid, y: heightGrid })
    }

    image.onerror = (err) => cb(err);

    image.src = url;

}

export default GetImageAspectRatio