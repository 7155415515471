import React, { useEffect, useState, useRef } from "react";
import { signIn_popup_open, signUp_popup_open } from '../scripts/script'
import '../style.css'
import user from '../images/user.svg'
import inMemoryID from '../services/id'
import inMemoryEmail from '../services/mail'
import { useNavigate } from "react-router-dom";
import { logOut, makeRefresh } from "../requests/authAPI";
import { deleteAccountPopUpOpen } from '../scripts/script'
import logout from '../images/logout.svg'
import trash from '../images/red-trash.svg'
import { auth } from "../firebaseConnect";

export default function RightTopCorner() {

    const navigate = useNavigate();

    const [content, setContent] = useState();

    const logOutFunction = async () => {
        await logOut().then(() => {
            navigate("/")
            window.location.reload()
        })
    }

    const withOutAccount = () => {
        setContent(
            <>
                <a onClick={signUp_popup_open} className="btn-sign-up">Sign up</a>
                <a onClick={signIn_popup_open} className="btn-sign-in">Sign in</a>
            </>
        )
    }

    const account = () => {
        setContent(
            <div className="user-profile">
                <p style={{margin: '0'}}>{inMemoryEmail.getEmail()}</p>
                <div className="icon" style={{margin: '0 0 0 10px'}}>
                    <img src={user} alt="users-profile-picture"/>
                </div>
                <div className="more-vertical" style={{margin: '0 0 0 10px'}}>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
                <div className="profile-dropdown">
                    <div>
                        <img src={logout} alt="logout icon" style={{marginLeft: '12px'}} />
                        <p onClick={logOutFunction}>Log out</p>
                    </div>
                    <div>
                        <img style={{margin:' 0 8px 0 10px'}} src={trash} alt="delete profile icon" className="delete-account-trash-icon" />
                        <p onClick={deleteAccountPopUpOpen} style={{color: '#cf1111'}}>Delete account</p>
                    </div>
                </div>
            </div>
        )
    }

    useEffect(() => {

        auth.onAuthStateChanged(async (user) => {
            if (!inMemoryID.getID() && user) {
                if (user.emailVerified === true) {
                    await makeRefresh(user).then((msg) => {
                        account()
                    }).catch((err) => {
                        withOutAccount()
                    })
                } else {
                    withOutAccount()
                }
            } else if (inMemoryID.getID()) {
                account()
            } else if (!user) {
                withOutAccount()
            }
        })

    }, [])

    return (
        content
    )
}