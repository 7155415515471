import axios from 'axios'
import JWTAccess from '../services/memory'
import DB from '../db'
import APIclient from './client'
import inMemoryID from '../services/id'
import inMemoryEmail from '../services/mail'
import { backEndMoodBoards, backEndAuth, frontEndDomain } from '../constants/API'
import { auth } from '../firebaseConnect';
import errorValidate from '../validators/errorValidator'
import { getAllUsersBoards, deleteAllUsersBoards } from './boardsAPI'
import { createUserWithEmailAndPassword, sendEmailVerification, signOut, sendPasswordResetEmail, signInWithEmailAndPassword, getAuth } from 'firebase/auth'

// client for auth
export const authClient = new APIclient(backEndAuth)

export async function logOut() {

    await signOut(auth).then(() => {
        DB.moodBoards.clear()
    }).catch((err) => {
        throw err
    })
}

export async function checkAccountData(password) {

    const user = auth.currentUser;

    const email = user.email;

    await signInWithEmailAndPassword(auth, email, password).then(async (res) => {
        await deleteAccount()
    }).catch((err) => {
        const validatedError = errorValidate(err.message)

        throw validatedError
    })
}

export async function deleteAccount() {
    await authClient.delete("/delete-profile").then((res) => {
        new Promise((resolve, reject) => {
            resolve(deleteAllUsersBoards())
        })
        window.location.reload()
    })
} 

export async function handleSignInWithPassAndEmail(signInEmail, signInPass) {
    
    await signInWithEmailAndPassword(auth, signInEmail, signInPass).catch((err) => {
        const validatedError = errorValidate(err.message)

        throw validatedError
    }).then(async (user) => {
        await handleSignIn(user)
    })
}

export async function handleSignIn(user) {
    await authClient.post("/sign-in", {
        UID: user.user.uid,
        role: 1
    }).then(async (res) => {
        await makeRefresh(user.user)
    }).then(() => {
        getAllUsersBoards().then(() => {
            window.location.reload();
        }).catch((err) => {

        })
    }).catch((err) => {
        throw err
    })
}

export async function handleSignUp(UID) {
    await authClient.post("/sign-up", {
        UID: UID,
        role: 1,
    }).then((res) => {
        window.location.href = `${frontEndDomain}/`;
    }).catch(err => {
        throw err
    })
}

export async function verifySignUpEmail(signUpEmail, signUpPass, redirect) {
    
    await createUserWithEmailAndPassword(auth, signUpEmail, signUpPass).then(async (credentials) => {
        await sendEmailVerification(credentials.user)
        redirect()
    }).catch(err => {
        const validatedError = errorValidate(err.message)

        throw validatedError
    })

}

export async function resetPassword(email) {

    return await sendPasswordResetEmail(auth, email)

}

// client for operating with moodboards
export const ResourceClient = new APIclient(backEndMoodBoards)

export async function makeRefresh(user) {

    if (user) {
        await axios.post(`https://securetoken.googleapis.com/v1/token?key=AIzaSyDcdmBXlMqNndavdRxKeCwMFOM1i2QQJ7Q`, {
            grant_type: 'refresh_token',
            refresh_token: user.refreshToken
        }).then((res) => {
            const newIdToken = res.data.id_token;
            JWTAccess.setToken(newIdToken, res.data.expires_in * 1000);
            inMemoryID.setID(res.data.user_id);
            inMemoryEmail.setEmail(user.email)
        }).catch((err) => {
            throw err
        })
    }

};