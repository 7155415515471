import * as ReactDOMClient from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Main from './pages/home';
import AllMoodBoards from './pages/boards';
import Preview from './pages/preview';
import Find from './pages/find'
import EmailVerify from './pages/verifyEmail'
import NotFoundPage from './pages/notFound'
import React from 'react';


const root = ReactDOMClient.createRoot(document.getElementById("root"));

const router = createBrowserRouter([
    {
        path: "/",
        children: [
            {
                path: "",
                element: <Main />,
            },
            {
                path: "find",
                element: <Find />
            },
            {
                path: "find",
                element: <Find />,
                children: [
                    {
                        path: ":moodBoard",
                        element: <Find/>
                    }
                ]
            },
            {
                path: "boards",
                element: <AllMoodBoards />
            },
            {
                path: "boards",
                element: <Preview />,
                children: [
                    {
                        path: ":moodBoard",
                        element: <Preview/>
                    }
                ]
            },
            {
                path: "verify-email",
                element: <EmailVerify/>
            }
        ]
    },
    {
        path: '*',
        element: <NotFoundPage/>
    },
]);

root.render(
    // <React.StrictMode>
        <RouterProvider router={router} />
    // </React.StrictMode>
)