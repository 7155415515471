import React from "react";
import { boardsLimitReachedPopUpOpen } from "../scripts/script";

function BoardsLimitReached(props) {
    return (
        <div className="all" id="boards-limit">
            <a className="popup-close" onClick={boardsLimitReachedPopUpOpen}></a>
            <div className="limit-exceed">
                <h2>You reached the limit of created boards</h2>
                <button className="btn-confirm" onClick={boardsLimitReachedPopUpOpen}>Okay</button>
            </div>
        </div>
    )
}

export default BoardsLimitReached