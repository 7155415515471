import React, { useEffect, useState } from "react";
import inMemoryID from '../services/id'
import { search_keyword, signUp_popup_open } from '../scripts/script'

function SearchTextBar(props) {

    const [keywords, setKeywords] = useState();

    const DefaultKeyWordsArray = ['architecture', 'interiors', 'furniture', 'lightings', 'urban'];

    // function to make call to API when clicking on the text below search
    const getText = (e) => {
        if (inMemoryID.getID()) {
            search_keyword(e.target.innerText);
            props.searchFunc(e);
        } else {
            signUp_popup_open();
        };
    };

    useEffect(() => {

        const keyWordsArray = [];

        // if (props.searchKeyWords.length === 0) {
        DefaultKeyWordsArray.map((word, i) => {
            keyWordsArray.push(<p onClick={getText} className='search-hints' key={'text-hint'+i}>{word}</p>)
        })
        // } else {
        //     props.searchKeyWords.map((word) => {
        //         keyWordsArray.push(<p onClick={getText} className='search-hints'>{word}</p>)
        //     })
        // }
        
        setKeywords(keyWordsArray)
    }, [props.searchFunc])

    return (
        <>
			{keywords}
        </>
    )
}

export default SearchTextBar