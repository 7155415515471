import React from "react";

function NoColorSelector(props) {
    return (
        <label>
            <input
                type="radio"
                className="side-window-radio-colour"
                name="colour"
                id={"color-"+props.index}
                onChange={props.handleDeleteColour}
            />
            <div className="color-selector-border">
                <svg
                    height="30"
                    width="30"
                    xmlns="http://www.w3.org/2000/svg"
                    >
                    <line
                        x1="0"
                        y1="0"
                        x2="30"
                        y2="30"
                        style={{
                            stroke: '#E0E0E0',
                            strokeWidth: '2',
                        }}
                    />
                </svg>
            </div>
        </label>
    )
}

function BlackAndWhiteSelector(props) {
    return (
        <label>
            <input
                type="radio"
                className="side-window-radio-colour"
                name="colour"
                id={"color-"+props.index}
                value={props.color.color}
                onChange={props.handleChangeColour}
            />
            <div
                className="color-selector-border"
                style={{
                    background:
                        'linear-gradient(45deg, black 50%, white 50%)',
                }}
            ></div>
        </label>
    )
}

function ColorSelector(props) {

    let className = "side-window-radio-colour";

    if (props.grayBorder) {
        className += " black-selector"
    }

    return (
        <label>
            <input
                type="radio"
                className={className}
                name="colour"
                id={"color-"+props.index}
                value={props.color.color}
                onChange={props.handleChangeColour}
            />
            <div
                className="color-selector"
                style={{ background: props.color.background }}
            ></div>
        </label>
    )
}

function ColorSelectors(props) {
    const allColors = props.colors

    const selectors = []

    let i = 1;

    allColors.map((color) => {
        if (!color.color) {
            selectors.push(
                <NoColorSelector key={i} handleDeleteColour={props.handleDeleteColour} index={i} />
            )
        } else if (color.color == 'black_and_white') {
            selectors.push(
                <BlackAndWhiteSelector key={i} handleChangeColour={props.handleChangeColour} color={color} index={i} />
            )
        } else if (color.color == 'black') {
            selectors.push(
                <ColorSelector grayBorder={true} key={i} handleChangeColour={props.handleChangeColour} color={color} index={i} />
            )
        } else {
            selectors.push(
                <ColorSelector key={i} handleChangeColour={props.handleChangeColour} color={color} index={i} />
            )
        }
        i++;
    })

    return selectors
}

export default ColorSelectors