import React from "react";
import { ImageSizeLimitReachedPopUpOpen } from "../../scripts/script";

function ImageSizeLimitReached(props) {
    return (
        <div className="all" id="image-size-limit">
            <a className="popup-close" onClick={ImageSizeLimitReachedPopUpOpen}></a>
            <div className="limit-exceed">
                <h2>The file is too big, the limit is 10 MB</h2>
                <button className="btn-confirm" onClick={ImageSizeLimitReachedPopUpOpen}>Okay</button>
            </div>
        </div>
    )
}

export default ImageSizeLimitReached