const inMemoryEmail = () => {
    let inMemoryID = null;

    const getEmail = () => inMemoryID;

    const setEmail = (IDgenerator) => {
        inMemoryID = IDgenerator
    }

    const deleteEmail = () => {
        inMemoryID = null
    }

    return { setEmail, deleteEmail, getEmail }
};

export default inMemoryEmail()