import React, { useEffect, useState } from "react"
import { renameBoardPopUpOpen } from "../scripts/script";
import edit from '../images/edit.svg';
import download from '../images/download.svg'
import del from '../images/trash.svg';
import inMemoryID from '../services/id';
import share from '../images/share-2.svg';
import rename from '../images/rename-icon.svg';
import '../style.css';


function PreviewButtons({moodBoardName, deleteMB, editMB, saveMBasPDF, shareMB, previewMoodBoard}) {
    
    const [buttons, setButtons] = useState()

    const userIsNotOwner = () => {
        setButtons(
            <div style={{display: 'flex', width: '100%', justifyContent: 'right'}}>
                <div className="panel-board">
                    <div className="point" onClick={shareMB} >
                        <img src={share} style={{margin: '0 8px', height: '24px'}} alt="delete icon"/>
                        Share
                    </div>
                    <div className="point" onClick={saveMBasPDF}> 
                        <img src={download} alt="download icon"/>
                        Download
                    </div>
                </div>
            </div> 
        )
    }

    const sendEditMoodBoardRequest = (e) => {
        editMB(previewMoodBoard)
    }
    
    useEffect(() => {

        let interval;

        interval = setInterval(() => {
            if (previewMoodBoard) {
                if (inMemoryID.getID()) {
                    clearInterval(interval)
                    if (inMemoryID.getID() === previewMoodBoard.created_by) {
                        setButtons(
                            <div className="panel-board">
                                <div className="point" onClick={renameBoardPopUpOpen}>
                                    <img src={rename} style={{ padding: "0 12px", height: '16px'}} />
                                    Rename
                                </div>
                                <div className="point" onClick={shareMB} >
                                    <img src={share} style={{margin: '0 8px', height: '24px'}} alt="delete icon"/>
                                    Share
                                </div>
                                <div className="point" onClick={sendEditMoodBoardRequest}>
                                    <img src={edit} alt="edit icon"/>
                                    Edit
                                </div>
                                {/* <div className="point">
                                    <img src={saveAs} style={{margin: "0 12px"}}/>
                                    Save as
                                </div>
                                <div className="point">
                                    <img src={expo} style={{margin: "0 12px"}}/>
                                    Export
                                </div> */}
                                <div className="point" onClick={saveMBasPDF}> 
                                    <img src={download} alt="download icon"/>
                                    Download
                                </div>
                                <div className="point" onClick={deleteMB}>
                                    <img src={del} alt="delete icon"/>
                                    Delete
                                </div>
                            </div>
                        )
                    } else if (previewMoodBoard.created_by !== inMemoryID.getID()) {
                        userIsNotOwner();
                    } 
                } else {
                    userIsNotOwner();
                }
            }
        }, 300)
    }, [moodBoardName])

    return buttons
}

export default PreviewButtons