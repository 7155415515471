import JWTAccess from '../services/memory'
import axios from 'axios'

class APIclient {
    constructor(backEndDomain) {
        const client = axios.create({
            baseURL: backEndDomain,
            withCredentials: true,
        })
        
        client.interceptors.request.use((config) => {
            const accessToken = JWTAccess.getToken();
        
            if (accessToken) {
                config.headers["Authorization"] = `Bearer ${accessToken}`
            }
        
            return config
        }, (err) => {
            Promise.reject(err)
        })

        return client
    }
}

export default APIclient