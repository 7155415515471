import React from "react";
import { useNavigate } from 'react-router-dom'

function NotFoundPage() {

    const navigate = useNavigate();

    const moveToMain = () => {
        navigate('/')
    }

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}>
            <div>
                <h2>PAGE NOT FOUND</h2>
                <p style={{ color: "grey", textAlign: 'center', margin: '30px 0 0 0', fontSize: '13px' }}>
                    <a style={{ color: 'rgb(45, 156, 219)' }} onClick={moveToMain}>Move to main page</a>
                </p>
            </div>
        </div>
    )
}

export default NotFoundPage