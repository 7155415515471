const inMemoryID = () => {
    let inMemoryID = null;

    const getID = () => inMemoryID;

    const setID = (IDgenerator) => {
        inMemoryID = IDgenerator
    }

    const deleteID = () => {
        inMemoryID = null
    }

    return { setID, deleteID, getID }
};

export default inMemoryID()