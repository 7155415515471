const errorValidate = (error) => {
    switch (error) {
        case 'Firebase: Error (auth/email-already-in-use).':
            return new Error("User with that email already exists")
        case 'Firebase: Error (auth/invalid-credential).':
            return new Error("You are provided wrong data or this user doesn't exist")
        case 'Firebase: Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. (auth/too-many-requests).':
            return new Error("Too many access requests to this account")
        case 'Firebase: Error (auth/admin-restricted-operation).':
            return new Error("Something happened on the server")
        default:
            return new Error(error)
    }
}

export default errorValidate