import React, { useState, useEffect, useRef, useId } from "react";
import CreateImg from '../components/resultImage';
import Packery from 'packery';
import Masonry from "react-responsive-masonry"
import { AddPhotoToGrid, AddEditPhotoToGrid } from './addPhoto'
import notFoundIcon from '../images/file-error-svgrepo-com.svg'
import inMemoryID from '../services/id'
import { signUp_popup_open } from '../scripts/script'
import { SearchLoadingScreen } from '../components/loader';
import Dropzone from '../components/dropZone'

function ResultsAndMB(props) {

    const isGridInitialized = useRef(false);

    // adding element to state
    const handleClick = (e, data) => {
        if (!inMemoryID.getID()) {

            signUp_popup_open()

        } else if (inMemoryID.getID()) {

            setSelectedImages(prevSelectedImages => {
                let sameComponentsName = 0;

                prevSelectedImages.forEach((item) => {
                    if (item.key == "react-key-" + (e.target.id).replace('btn-icon-', '') + `-${sameComponentsName}`) {
                        sameComponentsName += 1;
                    };
                });

                const reactKey = "react-key-" + (e.target.id).replace('btn-icon-', '') + `-${sameComponentsName}`

                return [...prevSelectedImages, <AddPhotoToGrid pckry={packery.current} photoData={data} elem={e} key={reactKey} reactKey={reactKey} removeFunc={removeFromTheState} />]
            });

        }
    }

    const removeFromTheState = (id) => {
        setSelectedImages(prevSelectedImages => prevSelectedImages.filter(item => item.key !== id));
    }

    function getUserImage(file, fileInfo) {

        if (!inMemoryID.getID()) {
            signUp_popup_open()
        } else if (inMemoryID.getID()) {

            setSelectedImages(prevSelectedImages => {
                let sameComponentsName = 0;

                prevSelectedImages.forEach((item) => {
                    if (item.key == "react-key-local-image" + `-${sameComponentsName}`) {
                        sameComponentsName += 1;
                    };
                });

                const reactKey = "react-key-local-image" + `-${sameComponentsName}`;

                const imageInfo = {
                    id: null,
                    alt: fileInfo.name,
                    url: file,
                    user: null
                }

                return [...prevSelectedImages, <AddEditPhotoToGrid item={imageInfo} pckry={packery.current} key={reactKey} reactKey={reactKey} removeFunc={removeFromTheState} />]
            });

            setTimeout(() => {
                packery.current.layout()
            }, 300)
        }
    }

    useEffect(() => {
        props.clearMoodBoardFunc.current = () => {
            setSelectedImages((moodBoardImages) => {

                moodBoardImages.map((item) => {
                    const key = item.key;
                    const imageId = key.replace('react-key-', 'result-image-').replace('-0', '')
                    const imageElement = document.getElementById(imageId)
                        
                    if (imageElement) {
                        imageElement.style.display = 'grid';
                    }
                })

                return []
            })
        }
    }, [])

    // images that user selected
    const [selectedImages, setSelectedImages] = useState([]);

    // result images
    const [resultImages, setResultImages] = useState([]);

    // packery.js
    const packery = useRef()

    // loader
    const [loader, setLoader] = useState(null)

    let timeOut = useRef(0);

    // displaying result of search
    useEffect(() => {

        let resultArrayLength;

        // array with created react components
        const renderArray = [];

        if (props.search) {

            resultArrayLength = Array.from(props.result).length;

            if (props.search && props.search.trim() != '' && resultArrayLength == 0 && !resultImages[0]) {
                setResultImages(
                    (
                        <div className='select-block-empty'>
                            <img src={notFoundIcon} alt="nothing found icon" />
                            <h1>Nothing found</h1>
                        </div>
                    )
                )
            } else if (props.search.trim() != '') {

                timeOut.current = 0

                if (resultArrayLength > 0) {
                    Array.from(props.result).forEach((element) => {

                        if (element.user) {
                            renderArray.push(<CreateImg source={element.source} id={element.id} alt={element.alt} user={element.user} search={props.search} download_url={element.download_location} handleClick={handleClick} />)
                        } else {
                            renderArray.push(<CreateImg source={element.source} id={element.id} alt={element.alt} search={props.search} handleClick={handleClick} />)
                        }

                        timeOut.current += 0.05
                    })
                }
    
                if (timeOut.current > 0 && props.page == 1) {
    
                    setLoader(
                        <div className="result-loader">
                            <SearchLoadingScreen />
                        </div>
                    )

                    setResultImages(null)

                    setResultImages(renderArray)
                    
                    props.resultRef.current.style.overflow = 'hidden';
    
                    setTimeout(() => {
                        setLoader(null)
                        if (props.resultRef.current) {
                            props.resultRef.current.style.overflow = 'scroll';
                        }
                    }, timeOut.current * 1000)
    
                } else if (resultImages[0] && props.page != 1) {

                    // setting search result images to the state
                    if (renderArray.length >= 40) {

                        const lastIndexOfFirstArr = renderArray.length / 2

                        const firstArray = renderArray.slice(0, lastIndexOfFirstArr - 1)
                        
                        const secondArray = renderArray.slice(lastIndexOfFirstArr, renderArray.length)

                        setResultImages((renderedImages) => [...renderedImages, ...firstArray])
                        
                        setTimeout(() => {
                            setResultImages((renderedImages) => [...renderedImages, ...secondArray])
                        }, 1000)
                    } else {
                        setResultImages((renderedImages) => [...renderedImages, ...renderArray])
                    }
                }
            }
        } else {
            if (!props.search) {
                setResultImages(
                    (
                        <div className='select-block-empty'>
                            <p style={{width:"230px", textAlign:"center"}}>Enter your first search request to start creating a mood board</p>
                        </div>
                    )
                )
            } 

        }
    }, [props.result])

    function initializePackery() {
        if (!isGridInitialized.current && props.result && props.gridRef.current && !packery.current) {

            const pckry = new Packery('#grid', {
                itemSelector: '.grid-item-cont',
                columnWidth: '.grid-column',
                gutter: '.grid-gutter',
                transitionDuration: 0,
                percentPosition: true,
                isOriginTop: true
            });

            packery.current = pckry;

            isGridInitialized.current = true;

        }
    } 
    
    useEffect(() => {

        // Creating moodBoard section with Packery
        if (!isGridInitialized.current && props.result && props.gridRef.current && !packery.current) {
            initializePackery()
        }
        
    }, [props.result, props.gridRef.current, props.editImages])

    useEffect(() => {
        if (selectedImages.length > 0) {
            setNoImagesBanner(null)
        }  else {
            setNoImagesBanner(
                <div className="selected-images">  
                    <div>
                        <p>Upload your image files here,
                            or click “add button” at the images list on the left to add selected images to the mood board
                        </p>
                    </div>
                </div>
            )
        }
    }, [selectedImages])

    const isEditPhotosAdded = useRef(false)

    // if edit moodBoard exists
    useEffect(() => {
        if (props.editImages && packery.current && !isEditPhotosAdded.current) {

            const EditArray = props.editImages;
            const ReactComponentsEditArr = [];

            const sortedEditArray = EditArray.sort(function(a, b) {
                return a.order - b.order;
            });
    
            let index = 0;

            sortedEditArray.forEach(item => {
                index++
                const editReactKey = "react-key-edit-" + (index)
                ReactComponentsEditArr.push(<AddEditPhotoToGrid pckry={packery.current} item={item} index={index} key={editReactKey} reactKey={editReactKey} removeFunc={removeFromTheState} />)
                timeOut += 0.5
            })

            isEditPhotosAdded.current = true;

            setTimeout(() => {
                packery.current.layout()
            }, timeOut * 1000)
    
            setSelectedImages(ReactComponentsEditArr);
            
        }    
    }, [props.editImages, packery.current])

    const isPhotosReloaded = useRef(false);

    // handlers that calling orderItems func
    if (packery.current) {

        packery.current.off('layoutComplete', orderItems);

        packery.current.on('layoutComplete', orderItems);
        
    }

    if (packery.current && isPhotosReloaded.current) {

        packery.current.on('dragItemPositioned', callOrderAndReload);
        
        packery.current.off('dragItemPositioned', callOrderAndReload);

    }

    // doing same that orderItems, but also reloading all react components in grid-section
    function callOrderAndReload() {
        orderItems(isPhotosReloaded.current)
    }

    // in case there are not enough pictures for scrolling
    useEffect(() => {
        if (packery.current) {
            packery.current.layout()
        }

        if (document.getElementById('select-block').clientHeight >= props.resultRef.current.scrollHeight && isGridInitialized.current && resultImages[1]) {
            props.isCalledUnsplashFunc()
        }
    }, [selectedImages])

    // this function assigns id to all elements one by one using packery.current.getItemElements
    function orderItems(reload) {
        if (isGridInitialized.current == true && packery.current) {

            setTimeout(() => {
                const itemElems = packery.current.getItemElements();
                itemElems.forEach((itemElem, i) => {

                    itemElem.id = 'cont-'+(i + 1);
                    
                });

                if (itemElems && packery.current && !reload) {

                    packery.current.reloadItems()
                    packery.current.layout()
                    isPhotosReloaded.current = true

                }
            }, 50)
        }
    }

    const [noImagesBanner, setNoImagesBanner] = useState();

    // in case that something re-rendered
    useEffect(() => {
        if (isPhotosReloaded.current = true) {
            isPhotosReloaded.current = false
            callOrderAndReload()
        }
    }, [props.editImages])

    return (
        <>
            <div id="main-grid-cont">
                <div style={{margin: "0", width: "100%"}} className="main-block" key={"unique-key"}>
                    <div className="select-block" id="select-block">
                        <div className="select-block-scroll-section" ref={props.resultRef}>
                            {loader}
                            <Masonry style={{margin: '0'}} columnsCount={3} gutter={3}>
                                {resultImages}
                            </Masonry>
                        </div>
                    </div>
                    <div style={{width: "100%"}} className="right-section" id='right-section'>
                        <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
                            <h2>Selected images</h2>
                            <Dropzone addImageFromFiles={getUserImage} />
                        </div>
                        <div className="grid-scroll-section">
                            <div className="grid-section" id="grid" ref={props.gridRef}>
                                <div className="grid-column"></div>
                                <div className="grid-gutter"></div>
                                {selectedImages}
                            </div>
                            {noImagesBanner}
                        </div>
                    </div>
                </div>
                <div id="enter-field">
                    <p style={{width:"230px", textAlign:"center"}}>Enter your first search request to start creating a mood board</p>
                </div>

            </div>

        </>
    )
}

export { ResultsAndMB }