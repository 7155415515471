export function search_keyword(element) {
    let input = document.querySelector('.input-1');
    let text = input.value;
    if (text == "") {
        text = element;
        input.value = text;
    } else {
        input.value = "";
        text = element;
        input.value = text;
    }
}

const body = document.getElementsByTagName('body')[0];

export function signIn_popup_open() {
    let allSignUpBackground = document.querySelector("#signUp");
    allSignUpBackground.classList.remove("active");
    let allSignInBackground = document.querySelector("#signIn");
    allSignInBackground.classList.toggle("active");
    body.style.overflow = (body.style.overflow === 'hidden') ? 'auto' : 'hidden';
}

export function signUp_popup_open() {
    let allSignInBackground = document.querySelector("#signIn");
    allSignInBackground.classList.remove("active");
    let allSignUpBackground = document.querySelector("#signUp");
    allSignUpBackground.classList.toggle("active");
    body.style.overflow = (body.style.overflow === 'hidden') ? 'auto' : 'hidden';
}

export function forgotPass_popup_open() {
    let all = document.querySelector("#forgot");
    all.classList.toggle("active");
    let alla = document.querySelector("#signIn");
    alla.classList.toggle("active");
}

export function sharePopUp_open(id) {
    let all = document.querySelector("#share-"+id)
    all.classList.toggle("active")
}

export function deleteAccountPopUpOpen() {
    let all = document.querySelector("#delete-profile")
    all.classList.toggle("active")
}

export function deleteBoardPopUpOpen(id) {
    let all = document.querySelector("#delete-board-"+id)
    all.classList.toggle("active")
}

export function clearBoardPopUpOpen() {
    let all = document.querySelector("#clear-board")
    all.classList.toggle("active")
}

export function renameBoardPopUpOpen() {
    let all = document.querySelector("#rename-board-background")
    all.classList.toggle("active")
}

export function boardsLimitReachedPopUpOpen() {
    let all = document.querySelector("#boards-limit")
    all.classList.toggle("active")
}

export function ImageSizeLimitReachedPopUpOpen() {
    let all = document.querySelector("#image-size-limit")
    all.classList.toggle("active")
}