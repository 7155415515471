import React, { useState, useRef, useEffect } from "react";
import plus from '../images/plus_icon.svg';

// creating result image element
function CreateImg(imageProps) {

    const imageRef = useRef();

    const containerRef = useRef();

    const [info, setInfo] = useState();

    const imageSelect = (e) => {
        imageProps.handleClick(e, info)
    }

    useEffect(() => {
        
        if (imageProps.user) {
            setInfo(JSON.stringify({user: imageProps.user, download_location: imageProps.download_url}))
        } else {
            setInfo(null)
        }

        if (imageRef.current.src) {
            setTimeout(() => {
                if (containerRef.current) {
                    containerRef.current.classList.add('active')
                }
            }, 500)
        }
    }, [imageProps])

    return (
        <div ref={containerRef} style={{position: 'static', display: 'grid', backgroundColor: 'black'}} className="result-cont" id={`result-image-${imageProps.id}-${imageProps.search}`} >
            <img ref={imageRef} className='result-image' style={{height: 'auto'}} src={imageProps.source.small} id={`img-${imageProps.id}-${(imageProps.search).replace(/ /g, '+')}`} alt={imageProps.alt} />
            <div className='small-white-icon' id={`btn-${imageProps.id}-${imageProps.search}`}>
                <img data-info={info} src={plus} height='24px' onClick={imageSelect} onTouchEnd={imageSelect} id={`btn-icon-${imageProps.id}-${imageProps.search}`} value={imageProps.id} data-url={imageProps.source.regular} />
            </div>
        </div>
    )
}

export default CreateImg