import React, { useEffect, useRef, useState } from "react";
import DB from '../../db';
import inMemoryID from '../../services/id';
import { manageAccessToNewUser } from '../../requests/boardsAPI';
import { ErrorAlert } from '../errors';
import trash from '../../images/red-trash.svg'

function GrantAccessToBoard({ previewMoodBoard, setPreviewMoodBoard }) {

    const input = useRef();

    const [errorMessage, setErrorMessage] = useState();

    const [usersWithAccess, setUsers] = useState([]);

    const [isUserOwner, setPermission] = useState(false);

    useEffect(() => {

        const checkIsUserHaveAccess = (board) => {
            setTimeout(() => {
                if (board && inMemoryID.getID()) {
                    setUsers(board.users_with_access ? board.users_with_access : []);
                    if (inMemoryID.getID() === board.created_by) {
                        setPermission(true);
                    }
                } else if (!board || !inMemoryID.getID()) {
                    checkIsUserHaveAccess(board)
                }
            }, 500)
        }

        if (previewMoodBoard) {
            checkIsUserHaveAccess(previewMoodBoard)
        }
        
    }, [previewMoodBoard, inMemoryID.getID()]);

    const sendRemoveUserRequest = (email) => {

        manageAccessToNewUser(email, previewMoodBoard.mbid, false, false).then((res) => {

            setPreviewMoodBoard((board) => {

                const boardCopy = structuredClone(board);

                boardCopy.users_with_access.forEach((user, i) => {
                    if (user.users_email === email) {
                        boardCopy.users_with_access.splice(i, 1)
                    }
                })

                DB.moodBoards.update(board.id, {
                    users_with_access: boardCopy.users_with_access,
                })

                setUsers([...boardCopy.users_with_access])

                return boardCopy
            })
        })

    }

    const sendRequest = async (e) => {
        if (previewMoodBoard) {
            try {
                const response = await manageAccessToNewUser(input.current.value, previewMoodBoard.mbid, true, false);
                setPreviewMoodBoard((board) => {
                    const boardCopy = structuredClone(board);

                    if (!boardCopy.users_with_access) {
                        boardCopy.users_with_access = [];
                    };

                    boardCopy.users_with_access.push(response.data);

                    DB.moodBoards.update(board.id, {
                        users_with_access: boardCopy.users_with_access,
                    })

                    delete response.data['message']

                    setUsers((prevUsers) => {
                        return [...prevUsers, response.data]
                    })

                    return boardCopy
                })
            } catch (err) {
                setErrorMessage(err.response.data)
            }
        }
    }

    return (
        <>
            {isUserOwner ? <>
                <ErrorAlert message={errorMessage} styles={{position: 'absolute', top: '-40%', left: '0', right: '0', marginLeft: 'auto', marginRight: 'auto' }}  />
                <div className="grant-access">
                    <div style={{display: 'flex', justifyContent: 'start'}}>
                        <h2>Manage access to this moodboard</h2>
                    </div>
                    <p style={{ margin: '12px 0'}}>Allow people to comment your moodboard</p>
                    <section style={{marginBottom: '10px'}}>
                        <button onClick={sendRequest} className="btn-confirm">Grant access</button>
                        <input ref={input} type="email" />
                    </section>
                    <section>
                        <ul>
                            {usersWithAccess ? usersWithAccess.map((user) => {

                                const sendRequestFunction = (e) => {
                                    sendRemoveUserRequest(user.users_email);
                                }

                                return (
                                    <li>
                                        <p>{user.users_email}</p>
                                        <div onClick={sendRequestFunction} >
                                            <img src={trash} alt="remove user access" />
                                        </div>
                                    </li>
                                )
                            }) : null}
                        </ul>
                    </section>
                </div>
            </> : null}
        </>
    )
}

export default GrantAccessToBoard