import * as Yup from 'yup'

const schema = Yup.object().shape({
    email: Yup.string()
    .email()
    .required()
    .min(10, "Email is too short, at least 10 symbols")
    .max(50, "Email is too long, maximum 50 symbols"),
    password: Yup.string()
    .required()
    .min(8, "Password is too short, at least 8 symbols")
    .max(40, "Password is too long, maximum 40 symbols")
});

const emailShema = Yup.object().shape({
    email: Yup.string()
    .email()
    .required()
    .min(10, "Email is too short, at least 10 symbols")
    .max(50, "Email is too long, maximum 50 symbols")
})

const passSchema = Yup.object().shape({
    password: Yup.string()
    .required()
    .min(8, "Password is too short, at least 8 symbols")
    .max(40, "Password is too long, maximum 40 symbols")
})

async function validateCheck(pass, email) {

    try {
        await passSchema.validate({password: pass})
    } catch (err) {
        throw err
    }

    try {
        await emailShema.validate({email: email})
    } catch (err) {
        throw err
    }
    
}

async function validatePassCheck(pass) {
    try {
        return await passSchema.validate({password: pass})
    } catch (err) {
        throw err
    }
}

export default schema

export { validateCheck, validatePassCheck }