import React, {useCallback} from 'react'
import { useDropzone } from 'react-dropzone'
import cloud from '../images/download-cloud.svg'
import imageCompression from 'browser-image-compression';
import { ImageSizeLimitReachedPopUpOpen } from "../scripts/script";

function Dropzone(props) {
    
    const onDrop = useCallback(acceptedFiles => {

        const bigSize = 0.5 * 1024 * 1024;

        const tooBigSize = 10 * 1024 * 1024;

        const options = {
            maxSizeMB: 0.256,
            maxWidthOrHeight: 1920,
            useWebWorker: true
        }

        acceptedFiles.forEach(file => {

            let isFileTooBig = false;

            if (file.size > bigSize) {
                isFileTooBig = true;
            }
            
            if (file.size > tooBigSize) {
                ImageSizeLimitReachedPopUpOpen()
                throw new Error('The file is too big, the limit is 50 MB')
            }

            const reader = new FileReader();

            reader.onload = async () => {
                if (isFileTooBig === true) {
                    const compressedFile = await imageCompression(file, options);

                    const encodedFile = await imageCompression.getDataUrlFromFile(compressedFile)

                    props.addImageFromFiles(encodedFile, compressedFile);
                } else {
                    props.addImageFromFiles(reader.result, file);
                }
            };
    
            reader.readAsDataURL(file);
    
        })

    }, [])

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'image/png': ['.png'],
            'image/svg': ['.svg'],
            'image/jpeg': ['.jpg']
        },
        maxFiles: 5,
        noDrag: true
    })

    return (
        <div {...getRootProps()} className="dropzone">
            <input {...getInputProps()} />
            <img src={cloud} alt="download icon" style={{margin: '0 6px 0 0'}} />
            <p>Upload</p>
        </div>
    )
}

export default Dropzone