import { CreatePhotosInGrid } from '../components/previewImages';
import { useEffect, useState, useRef, useCallback } from 'react';
import logo from '../images/logo.svg';
import smallArrow from '../images/arrow-right.svg';
import smallArrowEsc from '../images/arrow-left-escape.svg';
import inMemoryID from '../services/id'
import { useNavigate, useParams } from 'react-router-dom';
import { ForgotPasswordPopUp } from '../components/popups/forgotpass'
import { SignInPopUp } from '../components/popups/signin'
import { SignUpPopUp } from '../components/popups/signup'
import '../style.css'
import DB from '../db';
import ChangeScaleButtons from '../components/changeScale'
import { saveMoodBoardInDB } from '../scripts/saveMB';
import { deleteMBfromDB, getMoodBoard } from '../requests/boardsAPI'
import RightTopCorner from '../components/buttonsOrAccount'
import { saveMBpdf } from '../scripts/savePDF'
import PreviewButtons from '../components/previewButtons'
import DeleteProfilePopUp from '../components/popups/deleteAccounPopUp'
import DeleteMoodBoardPopUp from '../components/popups/deleteBoardPopUp'
import { deleteBoardPopUpOpen } from '../scripts/script'
import { SharePopUp } from '../components/popups/share'
import { sharePopUp_open } from '../scripts/script'
import RenameMoodBoardPopUp from '../components/renameBoard'
import PreviewImageWindow from '../components/previewImageWindow';
import GrantAccessToBoard from '../components/popups/grantAccess';


function Preview() {

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    
    let { moodBoard } = useParams();

    const [previewMoodBoard, setPreviewMoodBoard] = useState();

    const getBoards = async () => {
        const board = await DB.moodBoards.get({mbid: moodBoard})
        if (!board) {
            try {
                const board = await getMoodBoard(moodBoard)
                setPreviewMoodBoard(board.data)
            } catch (err) {
            }
        } else {
            setPreviewMoodBoard(board)
        } 
    }

    useEffect(() => {
        getBoards()
    }, [])

    const [MBname, setMBname] = useState();

    const MBid = useRef()

    const MBprimaryKey = useRef()

    useEffect(() => {
        MBid.current = moodBoard
    }, [])

    const [photoArrayLength, setPhotoArrayLength] = useState();

    const [changeScaleButtons, setChangeScaleButtons] = useState();

    useEffect(() => {
        if (previewMoodBoard) {
            console.log(previewMoodBoard)
            MBprimaryKey.current = previewMoodBoard.id
            setMBname(previewMoodBoard.name)
            setPhotoArrayLength(Array.from(previewMoodBoard.photos).length)
            setChangeScaleButtons(<ChangeScaleButtons itemsInBoard={Array.from(previewMoodBoard.photos).length} autoScale={autoScale} minusScale={minusScale} plusScale={plusScale} />)
        }
    }, [previewMoodBoard])

    // installing MoodBoard as PDF file
    function saveMBasPDF() {
        if (previewMoodBoard) {
            saveMBpdf(previewRef.current, MBname)
        }
    }

    const [previewImageData, setPreviewImageData] = useState();

    useEffect(() => {
        if (previewImageData) {
            setPreviewMoodBoard(moodboard => {
    
                if (moodboard) {
                    let moodBoardCopy = structuredClone(moodboard)
        
                    const newArray = [];
    
                    moodBoardCopy.photos.forEach((item) => {
                        if (item.id === previewImageData.id) {
                            newArray.push(previewImageData);
                        } else {
                            newArray.push(item);
                        }
                    });
        
                    moodBoardCopy.photos = newArray;
        
                    return moodBoardCopy
                }         
            });
        }
    }, [previewImageData])

    function adjustContextMenuZoneSize(element) {

        const contextMenuArea = document.querySelector('.context-menu-container');

        const image = document.querySelector('.preview-image');

        if (contextMenuArea && element) {

            const elementWidth = element.naturalWidth;
            const elementHeight = element.naturalHeight;
            const imageWidth = image.clientWidth;
            const imageHeight = image.clientHeight;

            const imageWidthAspectRatio = imageWidth / imageHeight;
            const elementWidthAspectRatio = elementWidth / elementHeight;

            const imageHeightAspectRatio = imageHeight / imageWidth;
            const elementHeightAspectRatio = elementHeight / elementWidth;

            if (imageWidthAspectRatio > elementWidthAspectRatio) {
                const oneWidth = imageWidth / imageWidthAspectRatio;
                const widthDifference = oneWidth * (imageWidthAspectRatio - elementWidthAspectRatio);
                const width = imageWidth - widthDifference;
                contextMenuArea.style.height = imageHeight + 'px';
                contextMenuArea.style.width = width + 'px';
            } else if (elementWidthAspectRatio > imageWidthAspectRatio) {
                const oneHeight = imageHeight / imageHeightAspectRatio;
                const heightDifference = oneHeight * (imageHeightAspectRatio - elementHeightAspectRatio);
                const height = imageHeight - heightDifference;
                contextMenuArea.style.height = height + 'px';
                contextMenuArea.style.width = imageWidth + 'px';
            } else {
                contextMenuArea.style.height = imageHeight + 'px';
                contextMenuArea.style.width = imageWidth + 'px';
            }
        }
    }

    // getting src of image to display it on img-preview
    function search_src(element, id) {

        const data = JSON.parse(element.dataset.image)

        let img = document.querySelector(".preview-image");
        img.src = data.url;
        img.id = id;
        img.style.objectFit = 'fit';
        
        img.setAttribute('data-image', (element.dataset.image))

        img.onload = () => {
            if (img.src) {
                adjustContextMenuZoneSize(img)
            }
        }

        setPreviewImageData(data);

        let imgClass = document.querySelector(".img-preview");
        imgClass.classList.toggle("active");
    };

    const isSomethingDragged = useRef(false);

    const previewRef = useRef(null);

    // deleting MoodBoard
    const navigateTo = useNavigate()

    // open delete moodboard popup
    const openDeletePopup = () => {
        deleteBoardPopUpOpen(MBprimaryKey.current)
    }

    async function deleteMB() {
        await deleteMBfromDB(MBid.current).then((res) => {
            DB.moodBoards.delete(MBprimaryKey.current)
            navigateTo("/boards/")
        })
    }

    // back to boards page
    async function back(e) {
        if (inMemoryID.getID() && previewMoodBoard) {
            if (previewMoodBoard.created_by === inMemoryID.getID()) {
                await saveMoodBoardInDB(previewRef, MBname, moodBoard, previewMoodBoard.recentSearches, previewMoodBoard.users_with_access, previewMoodBoard.created_by).then(() => {
                    navigateTo("/boards/")
                })
            } else {
                navigateTo("/boards/")
            }
        } else {
            navigateTo("/boards/")
        }
    }

    const MoveToMain = () => {
        navigateTo("/")
    }

    // redirecting to find page with moodboard
    async function editMB(board) {
        if (inMemoryID.getID() && board && MBname) { 
            console.log(board)
            if (board.created_by === inMemoryID.getID()) {
                await saveMoodBoardInDB(previewRef, MBname, moodBoard, board.recentSearches, board.users_with_access, board.created_by).then(() => {

                    const searchStocks = JSON.parse(window.localStorage.getItem('searchSources'));

                    let searchParamsString = '';

                    if (searchStocks) {

                        if (searchStocks.flickr) { searchParamsString += '&flickrSearch=true' }
                
                        if (searchStocks.unsplash) { searchParamsString += '&unsplashSearch=true' }

                        // if (searchStocks.adobe) { searchParamsString += '&adobeSearch=true' }

                    }

                    navigateTo(`/find/${moodBoard}/?query=${searchParamsString}`)
                })
            }
        }
    }

    function openSharePopUp() {
        sharePopUp_open(0)
    }

    function renamePopUp(value) {
        if (value) {
            setMBname(value)
        }
    }

    const imagePreviewWindow = useRef();
    
    const changeScaleFunction = useRef();

    const autoChangeScaleFunction = useRef();

    const plusScale = () => {
        changeScaleFunction.current('plus')
    }

    const minusScale = () => {
        changeScaleFunction.current('minus')
    }

    const autoScale = () => {
        const isBoardFit = autoChangeScaleFunction.current();
        return isBoardFit;
    }

    return (
        <>
            <PreviewImageWindow adjustContextMenuZoneSize={adjustContextMenuZoneSize} previewMoodBoard={previewMoodBoard} previewImageData={previewImageData} setPreviewImageData={setPreviewImageData} imagePreviewWindow={imagePreviewWindow} photoArrayLength={photoArrayLength} />
            <header className="manage">
                <a id="logo" onClick={MoveToMain}>
                    <img src={logo}/>
                </a>
                <div id="my_boards" onClick={back}>My boards</div>
                <div className="arrow-box">
                    <img src={smallArrow}/>
                </div>
                <div id="Untitled_board">{MBname}</div>
                <RightTopCorner />
            </header>
            <SignInPopUp />
            <SignUpPopUp />
            <ForgotPasswordPopUp />
            <DeleteProfilePopUp />
            <RenameMoodBoardPopUp boardName={MBname} renameFunc={renamePopUp} />
            <SharePopUp boardId={MBid} uniqueId={0} key={"share-board-popup-" + MBprimaryKey.current}>
                <GrantAccessToBoard setPreviewMoodBoard={setPreviewMoodBoard} previewMoodBoard={previewMoodBoard} />
            </SharePopUp>
            <DeleteMoodBoardPopUp id={MBprimaryKey.current} deleteFunc={deleteMB} key={"delete-board-popup-"+MBprimaryKey.current} />
            <div className="Board">
                <div className='MB-preview-name'>
                    <div className="escape" onClick={back}>
                        <img src={smallArrowEsc}/>
                    </div>
                    {MBname}
                </div>
                {changeScaleButtons}
                <PreviewButtons moodBoardName={MBname} deleteMB={openDeletePopup} editMB={editMB} saveMBasPDF={saveMBasPDF} shareMB={openSharePopUp} previewMoodBoard={previewMoodBoard} />
            </div>
            <div style={{display: 'grid', margin: '0 10px'}}>
                <div className="grid-container">
                    <div style={{display: "grid"}} className="preview-grid" ref={previewRef}>
                        <CreatePhotosInGrid isDragged={isSomethingDragged} previewMoodBoard={previewMoodBoard} preview={previewRef} mbinfo={{primKey: MBprimaryKey.current,name: MBname, mbid: MBid.current}} setPreviewImageFunc={search_src} setScaleFunc={changeScaleFunction} setAutoScaleFunc={autoChangeScaleFunction} />
                    </div>
                </div>
            </div>
        </>
    )
}

export default Preview