import React, { useEffect, useRef } from "react";
import { handleSignUp } from "../requests/authAPI";
import { auth } from '../firebaseConnect';
import logo from '../images/logo.svg';


function VerifyEmailPage() {

    const verifyPage = useRef()

    useEffect(() => {

        let interval;

        const checkEmailVerified = async () => {
            if (verifyPage.current) {
                const user = auth.currentUser;
                if (user) {
                    await user.reload(); // Reload user data
                    if (user.emailVerified) {
                        clearInterval(interval);
    
                        await handleSignUp(user.uid).catch((err) => {
 
                        });
                    } else {

                    };
                };
            }
        };
    
        if (verifyPage.current) {
            interval = setInterval(checkEmailVerified, 5000); // Check every 5 seconds
        }

    }, [])
    
    return (
        <div className="email-verification" ref={verifyPage}>
            <a style={{margin: '0 auto'}} href="/">
                <img src={logo} alt="gettyboards logo" />
            </a>
            <h1>Verification email page</h1>
            <p>Check your email address</p>
        </div>
    )
}

export default VerifyEmailPage;