import logo from '../images/logo.svg'
import { useEffect, useRef, useState } from 'react'
import { ForgotPasswordPopUp } from '../components/popups/forgotpass'
import { SignInPopUp } from '../components/popups/signin'
import { SignUpPopUp } from '../components/popups/signup'
import '../style.css'
import { LoadingScreen } from '../components/loader'
import DB from '../db'
import inMemoryID from '../services/id'
import { useNavigate } from 'react-router-dom'
import { getAllUsersBoards } from '../requests/boardsAPI'
import Packery from 'packery'
import RightTopCorner from '../components/buttonsOrAccount'
import { ErrorAlert } from '../components/errors'
import { signUp_popup_open } from '../scripts/script'
import DeleteProfilePopUp from '../components/popups/deleteAccounPopUp'
import BoardsImagePreview from '../components/boardIcon'
import BoardsLimitReached from '../components/boardsLimitReached'


function ReturnAllMoodBoards(props) {

    const [errorAlert, setErrorAlert] = useState();

    function removeSelfFromTheState(index) {
        setCreatedMBicons(currentMBicons => {
            return currentMBicons.filter((icon) => icon.key !== "board-" + index)
        })
    }

    const isBoardCopying = useRef(false)

    async function getAllBoardsFromIndexedDB() {
        const MoodBoards = await DB.moodBoards.toArray()
        if (MoodBoards[0] && inMemoryID.getID()) {

            let index = 0
            const createdMB = []

            MoodBoards.forEach(item => {
                index++
                props.setLoaderTimeOut(count => count += 500);
                createdMB.push(<BoardsImagePreview isBoardCopying={isBoardCopying} index={index} item={item} key={"board-"+index} deleteFunc={removeSelfFromTheState} getAllBoards={getAllBoardsFromIndexedDB} initPackeryFunc={initializePackery} />)
            })
            setCreatedMBicons(createdMB)

        } else if (!MoodBoards[0]) {
            props.setLoaderTimeOut(100);
        }
    }

    async function getUserBoards() {
        try {
            await getAllUsersBoards().then(() => {
                getAllBoardsFromIndexedDB()
            })
        } catch (err) {
            if (err.response) {
                setErrorAlert({error: err.response.data.error, status: err.response.data.status})
            } else {
                setErrorAlert({error: 'Somethins happened on the server...', status: '500' })
            }
        }
    }

    useEffect(() => {

        let interval;

        interval = setInterval(() => {
            if (inMemoryID.getID() && !createdMBicons[0]) {
                clearInterval(interval)
                getUserBoards()
            }
        }, 500)

    }, [])

    const [createdMBicons, setCreatedMBicons] = useState([])

    const initializePackery = (i) => {
        const pckry = new Packery(`#boards-grid-${i}`, {
            itemSelector: `.grid-item-boards`,
            columnWidth: '.grid-width',
            gutter: '.boards-grid-gutter',
            transitionDuration: 0,
            originTop: true,
            percentPosition: true
        });
        pckry.layout();

        return pckry
    }

    const navigateTo = useNavigate()

    const MoveToMain = () => {
        navigateTo("/")
    }

    const createNewBoard = (e) => {
        if (inMemoryID.getID()) {
            const searchStocks = JSON.parse(window.localStorage.getItem('searchSources'));

            let searchParamsString = '';

            if (searchStocks) {

                if (searchStocks.flickr) { searchParamsString += '&flickrSearch=true' }
    
                if (searchStocks.unsplash) { searchParamsString += '&unsplashSearch=true' }
    
                // if (searchStocks.adobe) { searchParamsString += '&adobeSearch=true' }
            }

            
            navigateTo(`/find/?query=${searchParamsString}`)
        } else {
            signUp_popup_open()
        }
    }

    return (
        <div>
            <header className="manage">
                <a id="logo" onClick={MoveToMain}>
                <img src={logo}/>
                </a>
                <a id="my_boards">My boards</a>
                <RightTopCorner/>
            </header>
            <SignInPopUp/>
            <SignUpPopUp/>
            <ForgotPasswordPopUp/>
            <DeleteProfilePopUp />
            <BoardsLimitReached />
            <ErrorAlert message={errorAlert} />
            <div className="Boards-cont">
                <div className="Boards-list">
                    <div className="rect-cont">
                        <div className="rect">
                            <a onClick={createNewBoard} className="plus">
                                <div className="line-1"></div>
                                <div className="line-2"></div>
                            </a>
                        </div>
                        <p>Create Moodboard</p>
                    </div>  
                    {createdMBicons}
                </div>
            </div>
        </div>
    )
}

function AllMoodBoards() {
    // loader function
    const [loading, setLoading] = useState(true);

    const [loaderTimeOut, setLoaderTimeOut] = useState(0);
  
    useEffect(() => {
 
        if (loaderTimeOut !== 0 && loading == true) {
            setTimeout(() => {
                setLoading(false);

            }, loaderTimeOut)
        }

    }, [loaderTimeOut]);

    return (
        <>
        {loading ? ( <div className='loader'><LoadingScreen loading={loading}/></div> ) : null}
            <ReturnAllMoodBoards setLoaderTimeOut={setLoaderTimeOut} />
        </>
    )
  }
  

export default AllMoodBoards