import React from "react";
import CustomToolTip from '../components/tooltips';
import settings from '../images/settings.svg'
import clear from '../images/clear-icon.svg';
import trash from '../images/trash.svg'
import eye from '../images/eye.svg'
import download from '../images/download.svg'
import share from '../images/share-2.svg'
import '../style.css'

function FindPageButtons(props) {
    return (
        <div className="find-panel-board">
            <CustomToolTip title={<p style={{fontSize: '13px', margin: '0'}}>Switch to preview</p>}>
                <div className="find-point" onClick={props.moveToPreview}>
                    <img src={eye} style={{ height: "32px", marginTop: "4px"}}/>
                </div>
            </CustomToolTip>
            <CustomToolTip title={<p style={{fontSize: '13px', margin: '0'}}>Download PDF</p>}>
                <div className="find-point" onClick={props.saveMBpdf}>
                    <img src={download} style={{ height: "24px"}}/>
                </div>
            </CustomToolTip>
            <CustomToolTip title={<p style={{fontSize: '13px', margin: '0'}}>Share a moodBoard</p>}>
                <div className="find-point" onClick={props.shareMB}>
                    <img src={share} style={{ height: "32px"}}/>
                </div>
            </CustomToolTip>
            <CustomToolTip title={<p style={{margin: '0 0 0 0', fontSize: '14px'}}>Clean moodboard</p>}>
                <div className="find-point settings-button" onClick={props.clearMB}>
                    <img src={clear} style={{ height: "26px"}} alt="settings icon"/>
                </div>
            </CustomToolTip>
        </div>
    )

}

export default FindPageButtons