import React from 'react';
import { saveMBpdf } from "../scripts/savePDF";
import { signUp_popup_open } from '../scripts/script';
import inMemoryID from '../services/id';
import { clearBoardPopUpOpen } from '../scripts/script'
import { sharePopUp_open } from '../scripts/script'
import FindPageButtons from '../components/findButtons'
import { CreateSelectors } from "../components/showType";
import CustomToolTip from '../components/tooltips';

function MoodBoardFunctionsPanel({ validateError, navigate, createSharePopUp, moodBoard, setInputState, inputState, MoodBoard, setMoodBoard, Grid, createdID, SaveMoodBoardFunction, isBoardSaved }) {

    const handleInputHover = () => {
        setInputState(prevState => ({ ...prevState, isHovered: true }));
    };
    
    const handleInputUnhover = () => {
        setInputState(prevState => ({ ...prevState, isHovered: false }));
    };
    
    const handleInputFocus = () => {
        setInputState(prevState => ({ ...prevState, isFocused: true }));
    };
    
    const handleInputBlur = () => {
        setInputState(prevState => ({ ...prevState, isFocused: false }));
    };

    const onChangeMBname = (e) => {
        setMoodBoard(board => {
            board.name = e.target.value;

            return board
        })
    };
    
    const setChangedMBname = (e) => {
        e.preventDefault();
        setMoodBoard(board => {
            board.name = e.target[0].value;

            return board
        })
        e.target[0].blur()
    }

    // Shows share moodboard pop-up
    const shareMB = async () => {
      
        if (moodBoard || createdID.current) {
            sharePopUp_open(1)
        } else if (!createdID.current && Grid.current.childNodes[2]) {

            try {
                createdID.current = await SaveMoodBoardFunction();
                if (createdID.current) {
                createSharePopUp()
                }
                setTimeout(() => {
                sharePopUp_open(1)
                }, 300)
            } catch (err) {
                validateError(err)
            }
        }
    }
    
  const saveMBpdfcall = (e) => {

        const isUserIDExists = inMemoryID.getID()

        if (!isUserIDExists) {
        signUp_popup_open()
        } else if (isUserIDExists) {
        saveMBpdf(Grid.current, MoodBoard.name);
        }
    }

    // redirect to preview mode
    const MoveToPreview = async (e) => {
        if (Grid.current.childNodes[2]) {
            if (inMemoryID.getID() && !isBoardSaved.current) {
                isBoardSaved.current = true;
                await SaveMoodBoardFunction()
                    .then((newId) => {
                if (!newId && createdID.current) {
                    navigate(`/boards/${createdID.current}/`)
                } else if (newId) {
                    navigate(`/boards/${newId}/`)
                }
                }).catch((err) => {
                    validateError(err)
                })
            } else if (!inMemoryID.getID()) {
                signUp_popup_open()
            }
        }
    }

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <div style={{ display: 'flex', width: '100%', flexWrap: 'wrap' }}>
                <form onSubmit={setChangedMBname}>
                    <CustomToolTip title={<p style={{fontSize: '13px', margin: '0'}}>Edit name</p>} open={inputState.isHovered && !inputState.isFocused}>
                    <input className="MB-name" 
                    maxLength={50}
                    type="text" 
                    placeholder="Untitled board" 
                    onChange={onChangeMBname} 
                    defaultValue={MoodBoard.name} 
                    label="Input"
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                    onMouseEnter={handleInputHover}
                    onMouseLeave={handleInputUnhover}
                    />
                    </CustomToolTip>
                </form>
                <FindPageButtons shareMB={shareMB} saveMBpdf={saveMBpdfcall} moveToPreview={MoveToPreview} clearMB={clearBoardPopUpOpen} />
            </div>
            <CreateSelectors/>
        </div>
    )

}

export default MoodBoardFunctionsPanel