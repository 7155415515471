import React, { useState } from "react";
import { deleteAccountPopUpOpen } from '../../scripts/script'
import { validatePassCheck } from '../../validators/auth'
import { checkAccountData } from '../../requests/authAPI'
import { ErrorPopUp } from '../errors'


function DeleteProfilePopUp() {

    const [password, setPassword] = useState();

    const [errorPopUp, setErrorPopUp] = useState();

    function passwordInput(e) {
        setPassword(e.target.value)
    }

    async function deleteProfile() {
        if (password) {
            if (password.trim() != '') {
                await validatePassCheck(password).catch((err) => {
                    setErrorPopUp(err)
                }).then(async (msg) => {
                    if (msg) {
                        await checkAccountData(msg.password)
                        .catch((err) => {
                            setErrorPopUp(err)
                        })
                    }
                })
            }
        }
    } 

    function restorePassword() {
        let forgot = document.querySelector("#forgot");
        forgot.classList.toggle("active");
        let deleteProfile = document.querySelector("#delete-profile")
        deleteProfile.classList.toggle("active")
    }

    return (
        <>
            <div className="all" id="delete-profile">
                <a onClick={deleteAccountPopUpOpen} className="popup-close"></a>
                <div className="delete-profile-popup">
                    <div className="input-box">
                        <h2>Now just a minute!</h2>
                        <p style={{textAlign: 'center', margin: '0 0 20px 0'}}> Are you sure you want to delete your whole account? Everything you’ve ever created will be gone forever. If you sure, confirm password in bellow.</p>
                        <p>Password</p>
                        <div className="search-box-3">
                            <input className="input-2" type="password" onChange={passwordInput}/>
                        </div>
                        <a onClick={restorePassword} style={{color: "#2D9CDB",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: "400",
                        lineHeight: "normal"}}
                        >Forgot password?</a>
                        <button onClick={deleteProfile} className="btn-confirm" style={{background: '#EB5757'}}>Delete account</button>
                    </div>
                    <ErrorPopUp message={errorPopUp} />
                </div>
            </div>
        </>
    )
}

export default DeleteProfilePopUp