import React, { useState, useRef } from 'react'
import useAutoSave from "../scripts/autoSave.hook";
import AutoSavePopUp from './popups/autoSavePopUp'
import inMemoryID from '../services/id'

function AutoSaveComponent({ validateError, Grid, createdID, createSharePopUp, SaveMoodBoardFunction }) {

    const showAutoSavePopUp = () => {
        const popup = document.querySelector('.auto-save-popup');
    
        popup.classList.toggle('active')
    
        setTimeout(() => {
            popup.classList.toggle('active')
        }, 5500)
      }
    
      const [currentDate, setCurrentDate] = useState();
    
      // auto-save logic
    useAutoSave(async () => {
        if (Grid.current.childNodes[2] && inMemoryID.getID()) {
            if (createdID.current) {
                await SaveMoodBoardFunction().catch((err) => {
                validateError(err)
                }).then((res) => {
                setCurrentDate(new Date())
                showAutoSavePopUp()
                })
            } else if (!createdID.current) {
                try {
                    createdID.current = await SaveMoodBoardFunction();
                    createSharePopUp()
                    setCurrentDate(new Date())
                    showAutoSavePopUp()
                } catch (err) {
                    validateError(err)
                }
            }
        }
    }, 45 * 1000)

    return <AutoSavePopUp currentTime={currentDate}/>

}

export default AutoSaveComponent